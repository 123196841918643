import { createLogic } from 'redux-logic';
import { Api } from 'shared/api.js';

export const name = 'presentation';

const getState = state => state[name];

/*
 * TYPES
 */

const prefix = `pages/${name}/`;

const FETCH_PRESENTATION = `${prefix}FETCH_PRESENTATION`;
const FETCH_PRESENTATION_SUCCESS = `${prefix}FETCH_PRESENTATION_SUCCESS`;
const FETCH_PRESENTATION_CANCEL = `${prefix}FETCH_PRESENTATION_CANCEL`;
const FETCH_PRESENTATION_FAIL = `${prefix}FETCH_PRESENTATION_FAIL`;
const SET_PRESENTATION_PASSWORD_REQUESTED = `${prefix}SET_PRESENTATION_PASSWORD_REQUESTED`;
const FETCH_PRIVATE_PRESENTATION_FAIL = `${prefix}FETCH_PRIVATE_PRESENTATION_FAIL`;
const FETCH_SHOP_PRESENTATION = `${prefix}FETCH_SHOP_PRESENTATION`;
const FETCH_SHOP_PRESENTATION_CANCEL = `${prefix}FETCH_SHOP_PRESENTATION_CANCEL`;
const FETCH_VIEWING = `${prefix}FETCH_VIEWING`;
const FETCH_VIEWING_SUCCESS = `${prefix}FETCH_VIEWING_SUCCESS`;
const FETCH_VIEWING_CANCEL = `${prefix}FETCH_VIEWING_CANCEL`;
const FETCH_VIEWING_FAIL = `${prefix}FETCH_VIEWING_FAIL`;
const FETCH_VIEWING_HOST = `${prefix}FETCH_VIEWING_HOST`;
const FETCH_VIEWING_HOST_SUCCESS = `${prefix}FETCH_VIEWING_HOST_SUCCESS`;
const FETCH_VIEWING_HOST_CANCEL = `${prefix}FETCH_VIEWING_HOST_CANCEL`;
const FETCH_VIEWING_HOST_FAIL = `${prefix}FETCH_VIEWING_HOST_FAIL`;
const INCREASE_PRESENTATION_VIEW_COUNT = `${prefix}INCREASE_PRESENTATION_VIEW_COUNT`;
const UPDATE_VIEWING = `${prefix}UPDATE_VIEWING`;
const UPDATE_VIEWING_SUCCESS = `${prefix}UPDATE_VIEWING_SUCCESS`;
const UPDATE_VIEWING_FAIL = `${prefix}UPDATE_VIEWING_FAIL`;
const UPDATE_VIEWING_CANCEL = `${prefix}UPDATE_VIEWING_CANCEL`;

const FETCH_EDITED_PRESENTATIONS_SUCCESS = `${prefix}FETCH_EDITED_PRESENTATIONS_SUCCESS`;
const FETCH_EDITED_PRESENTATIONS = `${prefix}FETCH_EDITED_PRESENTATIONS`;
const FETCH_EDITED_PRESENTATIONS_CANCEL = `${prefix}FETCH_EDITED_PRESENTATIONS_CANCEL`;
const FETCH_EDITED_PRESENTATIONS_FAIL = `${prefix}FETCH_EDITED_PRESENTATIONS_FAIL`;
const CLEAR_PRESENTATION = `${prefix}CLEAR_PRESENTATION`;


/*
 * ACTIONS
 */

const clearPresentation = () => ({
  type: CLEAR_PRESENTATION,
});

const fetchPresentation = options => ({
  type: FETCH_PRESENTATION,
  payload: {
    url: Api.presentations.get,
    method: 'GET',
    ...options
  },
});

const fetchEditedPresentations = () => ({
  type: FETCH_EDITED_PRESENTATIONS,
  payload: {
    url: Api.presentations.getEdited,
    method: 'GET'
  },
});

const fetchShopPresentation = options => ({
  type: FETCH_SHOP_PRESENTATION,
  payload: {
    url: Api.presentations.getShop,
    method: 'GET',
    ...options
  },
});

const fetchPresentationSuccess = data => ({
  type: FETCH_PRESENTATION_SUCCESS,
  data,
});

const fetchPresentationFail = () => ({
  type: FETCH_PRESENTATION_FAIL,
});

const setPresentationPasswordRequested = () => ({
  type: SET_PRESENTATION_PASSWORD_REQUESTED,
});

const fetchPrivatePresentationFail = () => ({
  type: FETCH_PRIVATE_PRESENTATION_FAIL,
});

const fetchViewing = options => ({
  type: FETCH_VIEWING,
  payload: {
    url: Api.viewings.get,
    method: 'GET',
    ...options
  },
});

const fetchViewingSuccess = data => ({
  type: FETCH_VIEWING_SUCCESS,
  data,
});

const fetchViewingFail = () => ({
  type: FETCH_VIEWING_FAIL,
});

const updateViewing = options => ({
  type: UPDATE_VIEWING,
  payload: {
    url: Api.viewings.changePresentation,
    method: 'POST',
    ...options
  }
});

const updateViewingSuccess = data => ({
  type: UPDATE_VIEWING_SUCCESS,
  data,
});

const updateViewingFail = () => ({
  type: UPDATE_VIEWING_FAIL,
});

const fetchViewingHost = options => ({
  type: FETCH_VIEWING_HOST,
  payload: {
    url: Api.viewings.getViewingHost,
    method: 'GET',
    ...options
  },
});

const fetchViewingHostSuccess = data => ({
  type: FETCH_VIEWING_HOST_SUCCESS,
  data,
});

const fetchViewingHostFail = () => ({
  type: FETCH_VIEWING_HOST_FAIL,
});

const fetchEditedPresentationsSuccess = data => ({
  type: FETCH_EDITED_PRESENTATIONS_SUCCESS,
  data,
});

const fetchEditedPresentationsFail = () => ({
  type: FETCH_EDITED_PRESENTATIONS_FAIL,
});

const increasePresentationViewCount = options => ({
  type: INCREASE_PRESENTATION_VIEW_COUNT,
  payload: {
    url: Api.presentations.increaseViewCount,
    method: 'PUT',
    ...options
  }
});

/*
 * REDUCER
 */

const initialState = {
  isLoading: false,
  content: null,
  meta: null,
  viewing: {
    isFound: false,
    isLoading: false,
  },
  isFound: true,
  editedSceneId: null,
  passwordRequested: false,
  error: false,
};

const reducer = (state = initialState, action) => {
  const actions = {
    [FETCH_PRESENTATION]: () => ({
      ...state,
      isLoading: true,
      isFound: true,
      error: false,
    }),
    [FETCH_PRESENTATION_SUCCESS]: () => {
      let meta,
        content;

      meta = action.data;
      content = meta.publicContent.content;
      delete meta['publicContent'];

      return {
        ...state,
        content,
        meta,
        isLoading: false,
        isFound: true,
        passwordRequested: false,
      };
    },
    [FETCH_PRESENTATION_FAIL]: () => ({
      ...state,
      isLoading: false,
      isFound: false,
      content: {},
    }),
    [FETCH_PRIVATE_PRESENTATION_FAIL]: () => ({
      ...state,
      isLoading: false,
      isFound: false,
      content: {},
      passwordRequested: true,
      error: true,
    }),
    [SET_PRESENTATION_PASSWORD_REQUESTED]: () => ({
      ...state,
      isLoading: false,
      isFound: false,
      content: {},
      passwordRequested: true,
    }),
    [FETCH_VIEWING]: () => {
      return {
        ...state,
        viewing: {
          isLoading: true,
          isFound: false,
        },
      };
    },
    [FETCH_VIEWING_SUCCESS]: () => {
      let data = action.data;

      return {
        ...state,
        content: data.content,
        viewing: {
          isFound: true,
          isLoading: false,
          email: data.email,
          hash: data.hash,
          id: data.id,
          creator: data.creator,
        },
      }
    },
    [FETCH_VIEWING_FAIL]: () => {
      return {
        ...state,
        viewing: {
          isFound: false,
          isLoading: false,
        },
      }
    },
    [FETCH_VIEWING_HOST]: () => {
      return {
        ...state,
        viewing: {
          isLoading: true,
          isFound: false,
        },
      };
    },
    [FETCH_VIEWING_HOST_SUCCESS]: () => {
      let data = action.data;

      return {
        ...state,
        content: data.content,
        viewing: {
          isFound: true,
          isLoading: false,
          email: data.email,
          hash: data.hash,
          id: data.id,
        },
      }
    },
    [FETCH_VIEWING_HOST_FAIL]: () => {
      return {
        ...state,
        viewing: {
          isFound: false,
          isLoading: false,
        },
      }
    },
    [UPDATE_VIEWING]: () => {
      return {
        ...state,
        viewing: {
          isLoading: true,
          isFound: false,
        },
      };
    },
    [UPDATE_VIEWING_SUCCESS]: () => {
      let data = action.data;

      return {
        ...state,
        content: data.content,
        viewing: {
          isFound: true,
          isLoading: false,
          email: data.email,
          hash: data.hash,
          id: data.id,
        },
      }
    },
    [UPDATE_VIEWING_FAIL]: () => {
      return {
        ...state,
        viewing: {
          isFound: false,
          isLoading: false,
        },
      }
    },
    [FETCH_EDITED_PRESENTATIONS]: () => ({
      ...state,
    }),
    [FETCH_EDITED_PRESENTATIONS_SUCCESS]: () => {
      let meta = action.data.currentPresentationMetadata,
        content = action.data.currentPresentation,
        editedSceneId = action.data.currentPresentationSceneId;

      return {
        ...state,
        content,
        meta,
        editedSceneId,
        isLoading: false,
        isFound: true,
      };
    },
    [INCREASE_PRESENTATION_VIEW_COUNT]: () => ({
      ...state,
    }),
    [CLEAR_PRESENTATION]: () => ({
      ...state,
      isLoading: false,
      content: null,
      meta: null,
      isFound: true,
    })
  };

  return (actions[action.type] && actions[action.type]()) || state;
};

/*
 * LOGIC
 */

const fetchPresentationLogic = createLogic({
  type: FETCH_PRESENTATION,
  cancelType: [FETCH_PRESENTATION_CANCEL],
  debounce: 100,
  process({ action: { payload }, httpClient, cancelled$ }) {
    if(payload.password) {
      return httpClient.cancellable({url: payload.url + payload.hash + '?password=' + payload.password}, cancelled$)
      .then(
        ({ data }) => fetchPresentationSuccess(data),
        () => fetchPrivatePresentationFail());
    }
    return httpClient.cancellable({url: payload.url + payload.hash}, cancelled$)
      .then(
        ({ data }) => fetchPresentationSuccess(data),
        (res) => {
          if(res.response.status === 401) {
            return setPresentationPasswordRequested();
          } else {
            return fetchPresentationFail();
          }
        })
  },
});

const fetchShopPresentationLogic = createLogic({
  type: FETCH_SHOP_PRESENTATION,
  cancelType: [FETCH_SHOP_PRESENTATION_CANCEL],
  debounce: 100,
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable({url: payload.url + payload.hash}, cancelled$)
      .then(
        ({ data }) => fetchPresentationSuccess(data),
        () => fetchPresentationFail());
  },
});

const fetchViewingLogic = createLogic({
  type: FETCH_VIEWING,
  cancelType: [FETCH_VIEWING_CANCEL],
  debounce: 100,
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable({url: '/api/v1/viewings/' + payload.hash}, cancelled$)
      .then(
        ({ data }) => fetchViewingSuccess(data),
        () => fetchViewingFail());
  },
});

const fetchViewingHostLogic = createLogic({
  type: FETCH_VIEWING_HOST,
  cancelType: [FETCH_VIEWING_HOST_CANCEL],
  debounce: 100,
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable({url: '/api/v1/viewings/' + payload.hash + '/host'}, cancelled$)
      .then(
        ({ data }) => fetchViewingHostSuccess(data),
        () => fetchViewingHostFail());
  },
});

const updateViewingLogic = createLogic({
  type: UPDATE_VIEWING,
  cancelType: [UPDATE_VIEWING_CANCEL],
  debounce: 100,
  process({ action: { payload }, httpClient, cancelled$ }) {
    let url = payload.url.replace(':hash', payload.hash);
    return httpClient.cancellable({url, method: payload.method, data: payload.data }, cancelled$)
      .then(
        ({ data }) => updateViewingSuccess(data),
        () => updateViewingFail());
  },
});

const fetchEditedPresentationsLogic = createLogic({
  type: FETCH_EDITED_PRESENTATIONS,
  cancelType: [FETCH_EDITED_PRESENTATIONS_CANCEL],
  debounce: 100,
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable({url: payload.url}, cancelled$)
      .then(
        ({ data }) => fetchEditedPresentationsSuccess(data),
        () => fetchEditedPresentationsFail());
  },
});

const increasePresentationViewCountLogic = createLogic({
  type: INCREASE_PRESENTATION_VIEW_COUNT,
  debounce: 100,
  process({ action: { payload }, httpClient, cancelled$ }, dispatch, done) {
    let url = payload.url.replace(':hash', payload.hash);

    httpClient.cancellable({url, method: payload.method}, cancelled$)
      .then(() => done())
      .catch(() => done());
  },
});

/*
 * SELECTORS
 */

const getPresentation = (state) => getState(state).content;

const getPresentationMeta = (state) => getState(state).meta;

const getEditedSceneId = state => getState(state).editedSceneId;

const getError = state => getState(state).error;

const isLoading = state => getState(state).isLoading;

const isFound = state => getState(state).isFound;

const getViewing = state => getState(state).viewing;

const passwordRequested = state => getState(state).passwordRequested;

/*
 * EXPORTS
 */

export default reducer;

export const actions = {
  fetchEditedPresentations,
  fetchPresentationSuccess,
  fetchPresentation,
  fetchShopPresentation,
  fetchViewing,
  fetchViewingHost,
  clearPresentation,
  increasePresentationViewCount,
  updateViewing
};

export const types = {
  FETCH_PRESENTATION,
  FETCH_PRESENTATION_SUCCESS,
  FETCH_PRESENTATION_CANCEL,
  FETCH_PRESENTATION_FAIL,
  SET_PRESENTATION_PASSWORD_REQUESTED,
  FETCH_PRIVATE_PRESENTATION_FAIL,
  FETCH_SHOP_PRESENTATION,
  FETCH_SHOP_PRESENTATION_CANCEL,
  INCREASE_PRESENTATION_VIEW_COUNT,
  CLEAR_PRESENTATION,
  FETCH_VIEWING_HOST,
  FETCH_VIEWING_HOST_CANCEL,
  FETCH_VIEWING_HOST_FAIL,
  FETCH_VIEWING_HOST_SUCCESS,
  UPDATE_VIEWING,
  UPDATE_VIEWING_CANCEL,
  UPDATE_VIEWING_FAIL,
  UPDATE_VIEWING_SUCCESS
};

export const logic = {
  fetchPresentationLogic,
  fetchShopPresentationLogic,
  fetchViewingLogic,
  fetchViewingHostLogic,
  fetchEditedPresentationsLogic,
  increasePresentationViewCountLogic,
  updateViewingLogic
};

export const selectors = {
  getPresentation,
  getPresentationMeta,
  getEditedSceneId,
  isLoading,
  isFound,
  getViewing,
  passwordRequested,
  getError,
};
