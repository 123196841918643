import { createLogic } from 'redux-logic';
import Config from 'config';
import { Api } from 'shared/api.js';
import i18next from "i18next";

export const name = 'auth';

export const AuthStates = {
  loading: 'loading',
  loggedIn: 'loggedIn',
  loggedOut: 'loggedOut',
  signedUp: 'signedUp',
  signedUpDenied: 'signedUpDenied',
};

const getState = state => state[name];

/*
 * TYPES
 */

const prefix = `${name}/`;

const FETCH_CURRENT_USER_SUCCESS = `${prefix}FETCH_CURRENT_USER_SUCCESS`;
const FETCH_CURRENT_USER = `${prefix}FETCH_CURRENT_USER`;
const FETCH_CURRENT_USER_FAIL = `${prefix}FETCH_CURRENT_USER_FAIL`;

const REGISTER_SUCCESS = `${prefix}REGISTER_SUCCESS`;
const REGISTER = `${prefix}REGISTER`;
const REGISTER_FAIL = `${prefix}REGISTER_FAIL`;

const LOGIN_SUCCESS = `${prefix}LOGIN_SUCCESS`;
const LOGIN = `${prefix}LOGIN`;
const LOGIN_FAIL = `${prefix}LOGIN_FAIL`;

const LOGOUT = `${prefix}LOGOUT`;
const LOGOUT_SUCCESS = `${prefix}LOGOUT_SUCCESS`;

const SEND_RESET_PASSWORD_LINK = `${prefix}SEND_RESET_PASSWORD_LINK`;
const SEND_RESET_PASSWORD_LINK_FAIL = `${prefix}SEND_RESET_PASSWORD_LINK_FAIL`;
const SEND_RESET_PASSWORD_LINK_SUCCESS = `${prefix}SEND_RESET_PASSWORD_LINK_SUCCESS`;

const SEND_CHANGED_PASSWORD = `${prefix}SEND_CHANGED_PASSWORD`;
const SEND_CHANGED_PASSWORD_FAIL = `${prefix}SEND_CHANGED_PASSWORD_FAIL`;
const SEND_CHANGED_PASSWORD_SUCCESS = `${prefix}SEND_CHANGED_PASSWORD_SUCCESS`;

const SEND_MESSAGE = `${prefix}SEND_MESSAGE`;
const CLEAR_REQUESTS_STATE = `${prefix}CLEAR_REQUESTS_STATE`;
const CLEAR_AUTH_STATE = `${prefix}CLEAR_AUTH_STATE`;

const ACCEPT_RODO = `${prefix}ACCEPT_RODO`;

const FETCH_OFFERS = `${prefix}FETCH_OFFERS`;
const FETCH_OFFERS_SUCCESS = `${prefix}FETCH_OFFERS_SUCCESS`;
const FETCH_OFFERS_FAIL = `${prefix}FETCH_OFFERS_FAIL`;

/*
 * ACTIONS
 */

const fetchCurrentUser = options => ({
  type: FETCH_CURRENT_USER,
  payload: {
    url: Api.auth.currentUser,
    method: 'get',
    ...options
  },
});

const fetchCurrentUserSuccess = data => ({
  type: FETCH_CURRENT_USER_SUCCESS,
  data,
});

const fetchCurrentUserFail = data => ({
  type: FETCH_CURRENT_USER_FAIL
});

const register = options => ({
  type: REGISTER,
  payload: {
    url: Api.auth.register,
    method: 'post',
    ...options
  },
});

const login = options => ({
  type: LOGIN,
  payload: {
    url: Api.auth.login,
    method: 'post',
    ...options
  },
});

const logout = () => ({
  type: LOGOUT,
  payload: {
    url: Api.auth.logout,
    method: 'post',
  }
});

const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

const sendResetPasswordLink = options => ({
  type: SEND_RESET_PASSWORD_LINK,
  payload: {
    url: Api.auth.resetPassword,
    method: 'post',
    ...options
  },
});

const sendResetPasswordLinkFail = data => ({
  type: SEND_RESET_PASSWORD_LINK_FAIL,
  data,
})

const sendResetPasswordLinkSuccess = () => ({
  type: SEND_RESET_PASSWORD_LINK_SUCCESS,
});

const sendMessage = options => ({
  type: SEND_MESSAGE,
  payload: {
    url: Api.mails.contact,
    method: 'post',
    ...options
  },
});

const sendChangedPassword = options => ({
  type: SEND_CHANGED_PASSWORD,
  payload: {
    url: Api.auth.verifyResetPassword,
    method: 'post',
    ...options
  },
});

const sendChangedPasswordSuccess = () => ({
  type: SEND_CHANGED_PASSWORD_SUCCESS,
});

const sendChangedPasswordFail = data => ({
  type: SEND_CHANGED_PASSWORD_FAIL,
  data,
});

const registerSuccess = data => ({
  type: REGISTER_SUCCESS,
  data,
  payload: {
    url: Api.auth.currentUser,
    method: 'get'
  },
});

const loginSuccess = data => ({
  type: LOGIN_SUCCESS,
	data,
	payload: {
		url: Api.auth.currentUser,
		method: 'get'
	},
});

const registerFail = data => ({
  type: REGISTER_FAIL,
  data,
});

const loginFail = data => ({
  type: LOGIN_FAIL,
  data,
});

const clearAuthState = options => ({
  type: CLEAR_AUTH_STATE,
});

const clearRequestsState = options => ({
  type: CLEAR_REQUESTS_STATE,
});

const acceptRodo = options => ({
  type: ACCEPT_RODO,
  payload: {
    url: Api.auth.acceptRodo,
    method: 'put',
    data: {marketingAgreementAccepted: false},
    ...options
  },
})

const fetchOffers = options => {
  return {
    type: FETCH_OFFERS,
    payload: {
      url: Api.offers.get,
      method: 'get',
      params: {
        locale: i18next.language,
        currency: options && options.currency
      }
    },
  }
};

const fetchOffersSuccess = data => ({
  type: FETCH_OFFERS_SUCCESS,
  data,
});

const fetchOffersFail = data => ({
  type: FETCH_OFFERS_FAIL,
  data,
});

/*
 * REDUCER
 */

const initialState = {
  state: AuthStates.loading,
  resetLinkSent: false,
  messageSent: false,
  changedPasswordSent: false,
  errorMsg: '',
  errorCode: '',
  serialNum: null,
  agreementsSeen: true,
  offers: {
    fetching: false,
    data: null,
  },
}

const reducer = (state = initialState, action) => {
  const actions = {
    [FETCH_CURRENT_USER]: () => ({
      ...state,
      state: AuthStates.loading,
    }),
    [FETCH_CURRENT_USER_SUCCESS]: () => ({
      ...state,
      state: AuthStates.loggedIn,
      ...action.data,
    }),
    [FETCH_CURRENT_USER_FAIL]: () => ({
      ...state,
      state: AuthStates.loggedOut,
    }),
    [REGISTER]: () => ({
      ...state,
      state: AuthStates.loading,
      errorMsg: '',
    }),
    [LOGIN]: () => ({
      ...state,
      state: AuthStates.loading,
      errorCode: '',
    }),
    [LOGOUT]: () => ({
      ...state,
      state: AuthStates.loggedOut,
      errorMsg: '',
    }),
    [SEND_RESET_PASSWORD_LINK]: () => ({
      ...state,
      errorCode: '',
      resetLinkSent: false,
    }),
    [SEND_RESET_PASSWORD_LINK_SUCCESS]: () => ({
      ...state,
      resetLinkSent: true,
    }),
    [SEND_RESET_PASSWORD_LINK_FAIL]: () => {
      let response = action.data.response.data;
      if (response && response.message) {
        return {
          ...state,
          errorCode: response.fieldErrors,
        }
      } else {
        return {
          ...state,
          errorCode: 'Default',
        }
      }
    },
    [SEND_CHANGED_PASSWORD]: () => ({
      ...state,
      errorMsg: '',
      errorCode: '',
      changedPasswordSent: false,
    }),
    [SEND_CHANGED_PASSWORD_SUCCESS]: () => ({
      ...state,
      changedPasswordSent: true,
    }),
    [SEND_CHANGED_PASSWORD_FAIL]: () => {
      let response = action.data.response.data,
        errorCode = response.code || 'DEFAULT';

      return {
        ...state,
        errorCode,
        changedPasswordSent: false,
      };
    },
    [SEND_MESSAGE]: () => ({
      ...state,
      messageSent: true,
    }),
    [REGISTER_SUCCESS]: () => ({
      ...state,
      state: AuthStates.signedUp,
      errorMsg: '',
    }),
    [LOGIN_SUCCESS]: () => ({
      ...state,
      state: AuthStates.loggedIn,
      errorCode: '',
      offers: {
        fetching: true,
        data: null,
      },
    }),
    [REGISTER_FAIL]: () => {
      let response = action.data.response.data;

      if (response && response.message) {
        return {
          ...state,
          state: AuthStates.signedUpDenied,
          errorCode: response.code,
          errorMsg: response.message
        }
      } else if (response && response.fieldErrors) {
        let fieldErrorsMsg = '';
        for (var prop in response.fieldErrors) {
          fieldErrorsMsg += (response.fieldErrors[prop]) + ' ';
        }
        return {
        ...state,
          state: AuthStates.signedUpDenied,
          errorCode: response.code,
          errorMsg: fieldErrorsMsg,
        }
      } else {
        return {
          ...state,
          state: AuthStates.signedUpDenied,
          errorCode: response.code,
          errorMsg: '',
        }
      }
    },
    [LOGIN_FAIL]: () => {
      let response = action.data.response.data,
        errorCode = response.code || 'DEFAULT';

      return {
        ...state,
        state: AuthStates.loggedOut,
        errorCode,
      }
    },
    [CLEAR_REQUESTS_STATE]: () => ({
      ...state,
      messageSent: false,
      changedPasswordSent: false,
      errorMsg: '',
      resetLinkSent: false,
    }),
    [CLEAR_AUTH_STATE]: () => ({
      ...state,
      state: AuthStates.loggedOut,
    }),
    [FETCH_OFFERS]: () => ({
      ...state,
      offers: {
        fetching: true,
        data: null,
      },
    }),
    [FETCH_OFFERS_SUCCESS]: () => ({
      ...state,
      offers: {
        fetching: false,
        data: action.data,
      },
    }),
    [FETCH_OFFERS_FAIL]: () => ({
      ...state,
      offers: {
        fetching: false,
        data: null,
      },
    }),
  };

  return (actions[action.type] && actions[action.type]()) || state;
};

/*
 * LOGIC
 */

const registerLogic = createLogic({
  type: REGISTER,
  latest: true,
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable(payload, cancelled$)
      .then(
        registerSuccess,
        registerFail);
  },
});

const sendResetPasswordLinkLogic = createLogic({
  type: SEND_RESET_PASSWORD_LINK,
  latest: true,
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable(payload, cancelled$)
      .then(
          sendResetPasswordLinkSuccess,
          sendResetPasswordLinkFail);
  },
});

const sendMessageLogic = createLogic({
  type: SEND_MESSAGE,
  process({ action: { payload }, httpClient, cancelled$ }) {
    httpClient.cancellable(payload, cancelled$);
  },
});

const sendChangedPasswordLogic = createLogic({
  type: SEND_CHANGED_PASSWORD,
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable(payload, cancelled$)
      .then(
        sendChangedPasswordSuccess,
        sendChangedPasswordFail,
      )
  },
});

const loginLogic = createLogic({
  type: LOGIN,
  latest: true,
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable(payload, cancelled$)
      .then(
        response => {
          if (['Editor', 'Sales'].includes(response.data.role)) {
            window.location = window.location.origin + '/madeByMe';
          } else {
            const toMatch = [
              /Android/i,
              /webOS/i,
              /iPhone/i,
              /iPad/i,
              /iPod/i,
              /BlackBerry/i,
              /Windows Phone/i
            ];
            if (!window.location.pathname.includes('/p') && !window.location.pathname.includes('/embed') && !toMatch.some((toMatchItem) => {
              return navigator.userAgent.match(toMatchItem);
            })) {
              window.location = window.location.origin + '/panel';
              window.location.refresh();
            } else {
              return loginSuccess(response.data);
            }
          }
        },
        loginFail);
  },
});

const logoutLogic = createLogic({
  type: LOGOUT,
  latest: true,
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable(payload, cancelled$)
      .then(
        (response) => {
          if (window.location.pathname.includes('/vh')) {
            window.location = window.location.origin;
            window.location.refresh();
          } else {
            return logoutSuccess(response);
          }
        }
      );
  },
});

const fetchCurrentUserLogic = createLogic({
	type: [
		FETCH_CURRENT_USER,
		LOGIN_SUCCESS,
	],
	cancelType: [ FETCH_CURRENT_USER_FAIL ],
	latest: true,
	process({ action: { payload }, httpClient, cancelled$ }) {
		return httpClient.cancellable(payload, cancelled$)
			.then(
				response => { return fetchCurrentUserSuccess(response.data)},
				fetchCurrentUserFail);
	},
});

const acceptRodoLogic = createLogic({
  type: ACCEPT_RODO,
  process({ action: { payload }, httpClient, cancelled$ }) {
    return httpClient.cancellable(payload, cancelled$)
      .then(fetchCurrentUser, fetchCurrentUser);
  },
});

const fetchOffersLogic = createLogic({
  type: [FETCH_OFFERS, LOGIN_SUCCESS ],
  process({ action: { payload }, httpClient, cancelled$ }) {
    let params = [];
    let apiUrl = Api.offers.get + '?locale=' + i18next.language.toUpperCase();
    if (payload && payload.params && payload.params.currency) {
      apiUrl = apiUrl + '&currency=' + payload.params.currency;
    }

    return httpClient.cancellable({url: apiUrl, method: 'get'}, cancelled$)
      .then(
        ({data}) => fetchOffersSuccess(data),
        ({data}) => fetchOffersFail(data)
      );
  },
});

// const fetchOffersLogic =

/*
 * SELECTORS
 */

const getCurrentUser = state => getState(state);

const getOffers = state => getState(state).offers;

/*
 * EXPORTS
 */

export default reducer;

export const actions = {
  fetchOffers,
  fetchCurrentUser,
  register,
  login,
  logout,
  sendResetPasswordLink,
  sendChangedPassword,
  sendMessage,
  clearRequestsState,
  clearAuthState,
  acceptRodo,
};

export const types = {
  FETCH_CURRENT_USER,
  FETCH_CURRENT_USER_SUCCESS,
};

export const logic = {
  fetchCurrentUserLogic,
  registerLogic,
  loginLogic,
  sendResetPasswordLinkLogic,
  sendChangedPasswordLogic,
  sendMessageLogic,
  acceptRodoLogic,
  logoutLogic,
  fetchOffersLogic,
};

export const selectors = {
  getCurrentUser,
  getOffers,
};
