import { createLogic } from 'redux-logic';
import Config from 'config';
import Prismic from 'prismic-javascript';
import { Api } from 'shared/api.js';

export const name = 'cms';

const token = Config.prismicToken;

const CMS_INIT = 'CMS_INIT',
    CMS_INIT_SUCCESS = 'CMS_INIT_SUCCESS',
    CMS_INIT_ERROR = 'CMS_INIT_ERROR',
    CMS_FAIL = 'CMS_FAIL',
    CMS_GET_ALL = 'CMS_GET_ALL',
    CMS_GET_ALL_SUCCESS = 'CMS_GET_ALL_SUCCESS',
    CMS_GET_ALL_ERROR = 'CMS_GET_ALL_ERROR';

const cmsInit = options => ({
    type: CMS_INIT,
    payload: {
        url: Api.cms,
        method: 'GET',
        ...options
    }
}),
    cmsInitSuccess = data => ({
        type: CMS_INIT_SUCCESS,
        payload: data
    }),
    cmsInitError = status => ({
        type: CMS_INIT_ERROR,
    }),
    cmsFail = status => ({
        type: CMS_FAIL
    }),
    cmsGetAll = options => ({
        type: CMS_GET_ALL,
        payload: options
    }),
    cmsGetAllSuccess = data => ({
        type: CMS_GET_ALL_SUCCESS,
        payload: data

    }),
    cmsGetAllError = () => ({
        type: CMS_GET_ALL_ERROR
    });

const getLevel = type => {
  switch(type) {
    case 'heading1': return 0;
    case 'heading2': return 1;
    case 'heading3': return 2;
    case 'heading4': return 3;
    case 'heading5': return 4;
    case 'heading6': return 5;
    default: return -1;
  }
};
const parseContent = content => {
  content = Object.values(content);
  if(content.length === 0){
    return [];
  }
  const levels = [[], [], [], [], [], []];
  content.filter(item => {
    const i = item[0];
    return i && i.text && i.text.trim().length;
  }).map(item => item[0])
    .forEach(item => {
      const currentLvl = getLevel(item.type);
      item.level = currentLvl;
      if (currentLvl >= 0) {
        levels[currentLvl][levels[currentLvl].length] = item;
        item.items = [];
        levels[currentLvl+1] = item.items;
        for (let i = currentLvl + 2; i < levels.length; i++) {
          levels[i] = [];
        }
      }
    });
  return levels[0];
};
const getResults = results => {
  const pages = {};
  results.forEach(result => {
    const title = result.data.title && result.data.title[0];
    const description =  result.data.description && result.data.description[0];
    const page = {
      title: title && title.text && title.text.trim() || '',
      description: description && description.text && description.text.trim() || ''
    };
    delete result.data.title;
    page.content = parseContent(result.data);
    pages[result.type] = page;
  });
  return pages;
};

const initialState = {
    isFetching: false,
    apiInit: false,
    failed: false,
    pages: { terms: [] }
}
const reducer = (state = initialState, action) => {
    const actions = {
        [CMS_INIT]: () => ({
            ...state,
            isFetching: true
        }),
        [CMS_FAIL]: () => ({
            ...state,
            isFetching: false,
            apiInit: false,
            failed: true
        }),
        [CMS_INIT_SUCCESS]: () => ({
            ...state,
            apiInit: true
        }),
        [CMS_INIT_ERROR]: () => ({
            ...state,
            apiInit: false
        }),
        [CMS_GET_ALL]: () => ({
            ...state,
            isFetching: true
        }),
        [CMS_GET_ALL_SUCCESS]: () => ({
            ...state,
            isFetching: false,
            pages: getResults(action.payload)
        })
    }
    return (actions[action.type] && actions[action.type]()) || state;
}
let cmsApi, cmsLang;
const cmsInitLogic = createLogic({
    type: CMS_INIT,
    cancelType: 'CMS_INIT_CANCEL',
    process({ action: { payload }, prismic }) {
        cmsLang = payload.lang;
        return prismic.getApi(Api.cms, { accessToken: token })
            .then(api => {
                cmsApi = api;
                return cmsInitSuccess();
            }).catch(err => {
                return cmsInitError()
            });
    }
}),
    cmsInitSuccessLogic = createLogic({
        type: CMS_INIT_SUCCESS,
        cancelType: 'CMS_INIT_SUCCESS_CANCEL',
        process() {
            return cmsGetAll();
        }
    }),
    cmsInitErrorLogic = createLogic({
        type: CMS_INIT_ERROR,
        cancelType: 'CMS_INIT_CANCEL',
        process() {
            cmsApi ?
                cmsInitSuccess() :
                cmsFail();
        }
    }),
    cmsGetAllLogic = createLogic({
        type: CMS_GET_ALL,
        cancelType: 'CMS_GET_ALL_CANCEL',
        process({ prismic }) {
            return cmsApi.query(Prismic.Predicates.any('document.type', ['privacy', 'terms']), { lang: cmsLang || 'en-us' })
                .then(res => cmsGetAllSuccess(res.results))
                .catch(err => cmsGetAllError())
        }
    });

const getState = state => state[name],
    getHome = state => getState(state).pages.home,
    getPricing = state => getState(state).pages.pricing,
    getRealEstate = state => getState(state).pages.realestate,
    getTerms = state => getState(state).pages.terms,
    getPrivacy = state => getState(state).pages.privacy,
    isApiInit = state => getState(state).apiInit;

export default reducer;

export const actions = {
    cmsInit,
    cmsGetAll
}
export const logic = {
    cmsInitLogic,
    cmsInitSuccessLogic,
    cmsGetAllLogic
};
export const selectors = {
    getHome,
    getPricing,
    getRealEstate,
    getTerms,
    getPrivacy,
    isApiInit
}
